<template>
  <div>
    <b-tabs v-show="shopeeToken!=null">
      <b-tab title="資料匯入" v-show="shopeeToken!=null">
          <b-card>
            <b-overlay :show="show" rounded="sm">
              <b-row>
                <b-col :cols="2">
                  <img :src="mspngSrc" alt="Logo" style="height:40px;"/>
                </b-col>
                <b-col :cols="7">
                  <b-form-group label="嘖嘖訂單上傳區:" label-for="file-small" label-cols-sm="4">
                    <b-form-file browse-text="選擇訂單Xlsx檔" id="orderInv" v-model="fileMsOrder" 
                     accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder="檔案拖移此處" drop-placeholder="拖移檔案至此..." />
                  </b-form-group>
                </b-col>
                <b-col :cols="3">
                  <b-button variant="success" @click="uploadZcOrderXls()">上傳</b-button>
                </b-col>
              </b-row>
            </b-overlay>
          </b-card>
          <b-row>
            <b-col cols="12" v-show="false">
              <b-card title="嘖嘖訂單下載教學">
                <img :src="mspngSrct1" style="height:40px;" @click="tourShow = true"/>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card title="最近上傳5筆">
                <b-table responsive="sm" :items="xlsHistory" :fields="uploadHistoryFields">
                  <template #cell(invQty)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.invQty}}</div>
                  </template>
                  <template #cell(price)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.price}}</div>
                  </template>
                  <template #cell(tax)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.tax}}</div>
                  </template>
                  <template #cell(fileName)="data">
                    <a href="#" target="_self" style="font-size:12px;" 
                        @click="downloadHistory(data.item.rowId,data.item.fileName)" 
                        >{{data.item.fileName}}</a> 
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </b-row>
      </b-tab>
    </b-tabs>
    <b-modal v-model="tourShow" centered title="賣貨便格式教學" size="xl" @shown="focusMyElement">
      <div>
        <swiper class="swiper-navigations" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
          <swiper-slide v-for="data in swiperData" :key="data.img">
            <b-img :src="data.img" fluid/>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination"/>
          <div slot="button-next" class="swiper-button-next"/>
          <div slot="button-prev" class="swiper-button-prev"/>
        </swiper>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right" @click="tourShow=false"> 關閉</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {
  BCard, BCardText, BCol, BRow, BFormGroup, BFormInput, BButton,BTab,BTabs,BFormFile,BOverlay,BTable,BPagination,BImg
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import vSelect from 'vue-select'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCol,
    BTab,
    BTabs,
    BRow,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,BTable,BPagination,BImg,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      tourShow:false,
      swiperOptions: {
        spaceBetween: 30,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperData: [

      ],
      shopeeToken: null,
      currentPage :1,
      pageSize :12,
      rows: 0,
      xlsHistory: [],
      show: false,
      tableBasic: [],
      name: '',
      taxType: null,
      taxTypeOptions: [{value: '1', text: '應稅'}],
      q: {
        qInvNo: '',
        qOrderNo: '',
        qCusNo:'',
      },
      fileMsOrder: null,
      token: null,
      cusNo: '',
      tab3UploadPrice: 0,
      tab3UploadTax: 0,
      tab3UploadCreate: 0,
      tab3UploadExistInvoiceQty:0,
      tab3UploadReturnInvoiceQty:0,
      tab3UploadReturnPrice: 0,
      tab3UploadExistOrderQty: 0,
      user: {
        cusNo: '',
        userId: '',
      },
      selectRowRtOrder:null,
      orderTableData:[],
      invDetails: [],
      uploadHistoryFields: [
        {key: 'fileName', label: '檔名'},
        {key: 'uploadDateDisplay', label: '上傳日期'},
        {key: 'invQty', label: '發票開立張數'},
        {key: 'finishIssueDateDisplay', label: '完成開立日期'},
      ],
      fields: [
        {key: 'orderNo', label: '單號'},
        {key: 'flow', label: '狀態'},
        {key: 'invNo', label: '發票號碼'},
        {key: 'invCreateDate', label: '發票開立日期'},
        {key: 'tax', label: '稅額'},
        {key: 'totalPrice', label: '金額'},
        {key: 'orderDate', label: '建立/完成日期'},
        {key: 'uploadDate', label: '上傳日期'},
        {key: 'editId', label: '明細'},
      ],
      jsonFileName:'',
      mspngSrc:process.env.BASE_URL + '/ordertype/zc.png',
      mspngSrct1:process.env.BASE_URL + '/ordertype/ms-tour-1.png',
    }
  },
  created() {
    let userData = { ability: [
      {action:'shopee',subject:'Auth'},
      // {action:'basic',subject:'Auth'},
    ]}
    this.$ability.update(userData.ability)
    // 從session來的
    let se = JSON.parse(sessionStorage.getItem('se'))
    if(se==null){
      return
    }
    this.shopeeToken = se.shopeeToken
    this.user.cusNo = se.cusNo
    this.user.userId = se.accountId
    this.q.qCusNo = se.cusNo

    this.getLastUploadTitle()
  },
  methods: {
    focusMyElement(){
      this.swiperData = [
        { img: process.env.BASE_URL + '/ordertype/ms-tour-1.png' },
      ]
    },
    parseDate(d){
      if(d!=null && d.length >= 12){
        return d.substring(4,6) + '/' +d.substring(6,8) + ' ' +d.substring(8,10) + ':'+d.substring(10,12) 
      }
      return ''
    },
    queryInvDetail (row) {
      this.selectRowRtOrder = row
      let params = {parentId : row.rowId, shopeeToken: this.shopeeToken}
      this.$http.get('../api/zec/queryInvDetail', {params}).then(res => {
        let seq = 1
        for(let i in res.data.data) {
          let d = res.data.data[i]
          res.data.data[i].seq = seq
          if(d.prodName.length > 10) {
            res.data.data[i].amt = d.qty*d.price
            res.data.data[i].prodName = d.prodName.substring(0,10) + '...'
          }
          seq++
        }
        this.invDetails = res.data.data
      })
    },
    queryInv (page) {
      if(page != null) {
        this.currentPage = page
      }
      let params = this.q
      params.page = this.currentPage
      params.pageSize = this.pageSize
      params.shopeeToken = this.shopeeToken

      this.$http.get('../api/zec/queryInv', {params}).then(res => {
        this.orderTableData = []
        res.data.data.data.forEach(e => {
          e.editId = e.rowId
          e.orderDate = { create: this.parseDate(e.orderCreateDate), finish: this.parseDate(e.orderFinishDate) }
          e.uploadDate = this.parseDate(e.uploadDate)
          if(e.invNo == '完成') {
            e.invNo = '開立中'
          }
          e.invCreateDate = this.parseDate(e.invCreateDate)
          this.orderTableData.push(e)
        })
        this.rows = res.data.data.totalRow
      })
    },
    pageClick(e,page){
      this.queryInv(page)
    },
    getFormatDate(str ,type){
      if(type == 'mm/dd hh:mm') {
        if( str!= null) {
          return str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      } else if(type == 'yyyy/mm/dd hh:mm') {
        if(str!= null) {
          return str.substring(0,4)+'/'+str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      }
    },
    getLastUploadTitle(){
      let params = {
        shopeeToken: this.shopeeToken,
      }
      this.$http.get('../api/zec/getTitleInfo', { params }).then(res => {
        res.data.data.forEach(e => {
          e.uploadDateDisplay = this.getFormatDate(e.uploadDate,'yyyy/mm/dd hh:mm')
          e.finishIssueDateDisplay = this.getFormatDate(e.finishIssueDate,'yyyy/mm/dd hh:mm')
          if(e.issueInvFlag == 'N' ) {
            e.finishIssueDateDisplay = '開立中'
            e.invQty = '開立中'
          }
        })
        this.xlsHistory = res.data.data
      })
    },
    downloadHistory (rowId,orgFileName) {
      axios({
          url: '../api/zec/zecFile/' + rowId,
          method: 'GET',
          responseType: 'blob',
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', orgFileName); 
          document.body.appendChild(link);
          link.click();
      });
    },
    uploadZcOrderXls(){
      // if(true){ return }
      if(this.fileMsOrder == null) {
        this.$bvToast.toast('錯誤', {
          title: `請選擇要上傳的檔案`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 1000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
        return
      }

      let headers = {
        'Accpet': 'application/json',
        'content-type' : 'multipart/form-data',
        Authorization: this.token,
      }
      let user = store.state.commonParam.user
      this.formData = new FormData()
      this.formData.append('fileMsOrder', this.fileMsOrder)
      this.formData.append('shopeeToken', this.shopeeToken)
      
      this.show = true
      this.$http.post('../api/zec/uploadZcOrderXls', this.formData, {headers}).then(res => {
        if(res.data.msg == 'success') {
          this.fileMsOrder  = null
          this.show = false
          this.tab3UploadPrice = res.data.data.price
          this.tab3UploadTax= res.data.data.tax
          this.tab3UploadCreate = res.data.data.create
          this.tab3UploadExistOrderQty = res.data.data.existOrderQty
          this.getLastUploadTitle()
          this.$toast({ component: ToastificationContent,
              props: {title: '上傳完成', variant: 'success', autoHideDelay: 2000, icon:'none'},
            })        
        } else {
          this.show = false
          this.$toast({ component: ToastificationContent,
              props: {title: res.data.data, variant: 'danger', autoHideDelay: 5000, icon:'none'},
            })        
        }
      }).catch(() =>{
        this.show = false
        this.$bvToast.toast('錯誤', {
          title: `上傳錯誤`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 5000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
      })
    },
    toast(toaster, append = false) {
      this.counter += 1
      this.$bvToast.toast('更新成功', {
        title: `Toaster ${toaster}`,
        toaster,
        variant: 'success',
        solid: false,
        appendToast: append,
      })
    },
  },
  computed:{
    canIssueInv(){
      if(this.selectRowRtOrder != null && this.selectRowRtOrder.invNo== null) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@/assets/scss/style.scss';

.history-table{
  border: 1px solid white;
  border-collapse: collapse;
}
// .dark-layout label {
//     font-size: 16px;
// }
</style>
