export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: '蝦皮賣家作業',
    route: 'shopee',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '蝦皮退貨折讓作業',
    route: 'shopeeReturn',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '露天賣家作業',
    route: 'ruten',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: 'Shopline賣家作業',
    route: 'shopline',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '奇摩賣家作業',
    route: 'kimo',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: 'FbBuy賣家作業',
    route: 'fbbuy',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: 'SARAASHOP賣家作業',
    route: 'saraashop',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: 'ShopStore賣家作業',
    route: 'shopstore',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: 'EasyStore賣家作業',
    route: 'easystore',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '賣貨便賣家作業',
    route: 'myship',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '超級好賣作業',
    route: 'supersale',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '團購訂單作業',
    route: 'fishgroup',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: 'Iopen訂單作業',
    route: 'iopen',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: 'Cyberbiz訂單作業',
    route: 'cyberbiz',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '好賣+訂單作業',
    route: 'humile',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  {
    title: '嘖嘖訂單作業',
    route: 'zec',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'shopee',
  },
  // {
  //   title: '蝦API訂單查詢',
  //   route: 'shopeeApi',
  //   icon: 'FileIcon',
  //   resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
  //   action: 'shopee',
  // },
  // {
  //   title: '蝦皮賣家作業',
  //   route: 'shopee',
  //   icon: 'FileIcon',
  //   resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
  //   action: 'shopee',
  // },
  // {
  //   title: '交易資料',
  //   route: 'data-page',
  //   icon: 'FileIcon',
  //   resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
  //   action: 'financial',
  // },
  {
    title: '基本資料設定',
    route: 'setting-page',
    icon: 'FileIcon',
    resource: 'Auth', //對應到@/lib/acl/config.js 檔案設定
    action: 'basic',
  },
]
